@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/colors';
@use 'assets/styles/mixin' as mx;

.card {
  position: relative;
  border: 1px solid colors.$gray-e9;
  border-radius: v.$border-radius-xxl;

  &.smallCard {
    overflow: hidden;
    height: 330px;
    border-width: 1px;
    border-radius: v.$border-radius-xxl;
    box-shadow: 0 0 0 rgb(0 0 0 / 0%);
    transition: box-shadow 250ms ease-in;

    &:hover {
      box-shadow: 0 5px 25px rgb(0 0 0 / 4%);
      transition: box-shadow 250ms ease-out;
    }
  }
}

.cover {
  display: block;
  width: 100%;
  height: 255px;
  border-top-left-radius: v.$border-radius-xxl;
  border-top-right-radius: v.$border-radius-xxl;
  object-fit: cover;
  object-position: top center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &.cover > img {
    width: 100%;
    height: 100%;
  }

  &.smallCover {
    height: 100%;
  }

  &.smallCover > img {
    width: 100%;
    height: 100%;
    filter: brightness(100%);
    transition: filter 250ms ease-in;
  }
}

.link {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  border: none;
  border-radius: v.$border-radius-xxl;
  background-color: colors.$white;
  border-bottom-left-radius: v.$border-radius-xxl;
  border-bottom-right-radius: v.$border-radius-xxl;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  touch-action: manipulation;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;

  .cover:hover {
    opacity: 0.9;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 3px 8px rgba((colors.$black), 0.05);
    color: inherit;
    text-decoration: none;
  }
}

.smallCover {
  border-top-left-radius: v.$border-radius-xxl;
  border-top-right-radius: v.$border-radius-xxl;
}

.tooltip {
  position: absolute;
  top: 1brm;
  right: 1brm;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.smallCardLink {
  overflow: hidden;
  min-height: 330px;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;

    .smallCardContent:not(.smallNoteCardContent) {
      top: 134px;
      opacity: 1;
      transform: translateY(-10px);
      transition: transform 250ms ease-out, top 250ms ease-out;
      visibility: visible;
    }

    .tooltip {
      top: 22px;
    }

    .smallCover {
      border-radius: v.$border-radius-xxl;

      img {
        filter: brightness(85%);
        transition: filter 250ms ease-out;
      }
    }
  }
}

.figure {
  position: relative;
  display: block;
  height: 255px;
  box-sizing: border-box;
  margin: 0;
  background-color: colors.$gray-e;
  color: rgba((colors.$black), 0.65);
  cursor: pointer;
  line-height: 30px;
  overflow-wrap: break-word;
  text-size-adjust: 100%;

  &.smallFigure {
    height: 184px;
  }
}

.cardHeader {
  padding: v.$sp-24;
  border-right: 1px solid colors.$gray-e;
  border-left: 1px solid colors.$gray-e;
  margin: 0 -1px;
  background: colors.$white;
  transition: transform 0.2s ease-in-out;

  &.smallCardHeader {
    display: flex;
    min-width: 100%;
    height: 147px;
    flex-direction: column;
    padding: v.$sp-18 v.$sp-20 v.$sp-20;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: v.$sp-8;
  color: inherit;
  cursor: pointer;
  font-size: v.$font-size-xxl;
  font-weight: 800;
  letter-spacing: -0.03em;
  line-height: 1.3;
}

.description {
  color: colors.$gray-5;
}

.content {
  position: relative;
  height: 15.5brm;
  font-size: v.$font-size-l;
  font-weight: 300;
  line-height: 1.3;

  &.smallCardContent {
    position: absolute;
    top: 185px;
    display: flex;
    min-width: 100%;
    height: 204px;
    flex-direction: column;
    justify-content: flex-end;
    background: colors.$white;
    border-bottom-left-radius: v.$border-radius-xxl;
    border-bottom-right-radius: v.$border-radius-xxl;
    transition: transform 150ms ease-in, top 150ms ease-in;
  }
}

.noteFooter {
  display: flex;
  flex-direction: column;
  border-top: 1px solid colors.$gray-e;
  opacity: 0;
  text-align: center;
  transform: translateY(1brm);
  transition: transform 0.2s ease-in-out;
  visibility: hidden;
}

.button {
  align-self: center;
  margin-top: v.$sp-48;
}

.secondaryDescription {
  padding: v.$sp-16 v.$sp-24;
  color: colors.$gray-7;
  font-size: v.$font-size-m;
  font-weight: 300;
  text-align: left;
}

.terms {
  transform: translateY(100px);

  &.smallCardTerms {
    transform: none;
  }
}

.noteTerms {
  position: absolute;
  right: 1.5brm;
  bottom: 0.5brm;
  left: 1.5brm;
  padding-left: 0;
  margin-bottom: 0;
  font-size: v.$font-size-l;
  line-height: 48px;
  list-style: none;
  transition: transform 0.2s ease-in-out;

  > li {
    border-top: 1px solid colors.$gray-e;
    line-height: 3brm;
    text-transform: capitalize;
  }

  &.smallCardNoteTerms {
    display: none;
  }
}

.termName {
  color: colors.$gray-7;
}

@include media.up(sm) {
  .linkAnimation {
    &:active,
    &:hover,
    &:focus {
      .cardHeader,
      .tooltip {
        transform: translateY(-104px);
      }

      .smallCardHeader {
        padding-bottom: 12px;
        transform: translateY(0);

        .regulationEntityText {
          height: 0;
          opacity: 0;
          transition: opacity 150ms ease-out, height 150ms ease-out;
        }
      }

      .cover {
        opacity: 0.9;
      }

      .smallCover {
        opacity: 1;
      }

      .terms:not(.smallCardTerms) {
        bottom: 0;
        opacity: 1;
        transform: translateY(-10px);
        transition: transform 0.2s ease-in-out;
        visibility: visible;
      }

      .smallCardTerms {
        transform: translateY(-8px);
      }

      .footer {
        border-bottom-left-radius: v.$border-radius-xxl;
        border-bottom-right-radius: v.$border-radius-xxl;
        opacity: 0;
        transform: translateY(105px);

        &.smallCardFooter {
          opacity: 1;
          transform: translateY(105px);
        }
      }

      .noteTerms {
        opacity: 0;
        transform: translateY(105px);
        transition-duration: 0s;
        visibility: hidden;
      }

      .noteFooter {
        opacity: 1;
        transform: translateY(-104px);
        visibility: visible;
      }

      .tooltip {
        opacity: 1;
      }
    }

    &.smallCardLink {
      .tooltip {
        transform: translateY(0);
      }
    }
  }

  .link {
    &:active,
    &:hover,
    &:focus {
      .tooltip {
        opacity: 1;
      }
    }
  }
}

@include media.only(sm) {
  .avatar {
    margin-top: -(v.$sp-24);
  }

  .content {
    min-height: 180px;
  }

  .figure {
    height: 224px;
  }
}

@include media.down(sm) {
  .tooltip {
    display: none;
  }
}

.follow.follow {
  position: absolute;
  z-index: 20;
  top: 0.25brm;
  right: 0.5brm;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.isSmallCard {
    top: 8px;
    right: 8px;
    display: flex;
    transition: opacity 250ms ease-in;
  }
}

.card:hover {
  .follow {
    opacity: 1;

    &.isSmallCard {
      transition: opacity 250ms ease-out;
    }
  }
}
