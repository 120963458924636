@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.badge.badge {
  padding: 5px 9px;
  border-color: transparent;
  border-radius: v.$border-radius-l;
  margin-right: v.$sp-8;
  margin-bottom: v.$sp-6;
  font-size: v.$font-size-xs;
  font-weight: 900;
  letter-spacing: -0.02em;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  &.simple {
    background-color: colors.$white;
    color: colors.$black-80;
  }

  &.trend,
  &.special {
    background-color: colors.$white;
    color: colors.$danger;
  }

  &.fullyFunded,
  &.fullyReserved {
    display: flex;
    align-items: center;
    background-color: colors.$republic-badge-green;
    color: colors.$white;
  }

  &.danger {
    display: flex;
    align-items: center;
    border: 0;
    background-color: red;
    color: colors.$white;
  }

  &.funded {
    background-color: colors.$gray-80;
    color: colors.$white;
  }

  &.new {
    background-color: colors.$white;
    color: colors.$brand-primary;
  }

  &.leftToInvest {
    background-color: colors.$white;
    color: colors.$republic-error;
    text-transform: lowercase;
  }

  &.investment {
    padding: v.$sp-6 v.$sp-8;
  }

  & .tagText::first-letter {
    text-transform: capitalize;
  }

  &.isSmallCardBadge {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &.spotlight {
    height: 32px;
    padding: 0 8px;
    border: none;
    margin: 0;
    background-color: rgb(0 0 0 / 40%);
    color: colors.$white;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    text-transform: capitalize;

    &:hover {
      background-color: rgb(0 0 0 / 60%);
    }
  }
}

.tooltipMain.tooltipMain {
  background-color: colors.$gray-80;
}

.icon {
  &.flame {
    path {
      fill: colors.$republic-error;
    }
  }
}
